.header {
  background-color: #14345c;
  width: 100%;
  .container-image {
    /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 90vh;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */ /* Replace with your mobile image URL */
      height: 35vh;
    }
  }
  .contact-header {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    border-style: double;
    border-color: white;
    border-width: 10px 0px 10px 0px;
    .judul-contact {
      padding: 10px;
      font-family: "Poppins";
      font-weight: 600;
      color: white;
      font-size: 24px;
    }
    .nomor-contact {
      font-family: "Poppins";
      font-weight: 400;
      color: white;
      font-size: 16px;
    }
    .button-contact {
      padding-top: 15px;
      padding-left: 20px;
      @media (max-width: 768px) {
        padding-left: 30px;
      }
      .contactus {
        margin-top: -8px;
        display: inline-block;
        padding: 15px 25px;
        cursor: pointer;
        border: none;
        border-radius: 36px;
        background-color: #25d366; /* WhatsApp green */
        color: rgb(237, 237, 237);
        font-size: 15px;
        @media (max-width: 768px) {
          padding: 13px 90px;
        }
      }
    }
  }
}
