.App {
  .floating-whatsapp-chatbox {
    visibility: hidden;
  }
  .floating-whatsapp {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .floatingwamobile {
    z-index: 9999999;
    display: none;
    width: 100%;
    align-items: center;
    justify-content: center;
    bottom: 0;
    height: 75px;
    position: fixed;
    background-color: white;
    box-shadow: 0 0 10px 0;

    @media (max-width: 768px) {
      display: flex;
    }
    .buttonmobile {
      display: none;

      border: none;
      padding: 8px 40px;
      border-radius: 6px;
      box-shadow: 5px 0 20px -4px #0000008c;
      color: #fff;
      background-color: #1c244b;
      cursor: pointer;
      font-size: 18px;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }
}
