@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-header {
  width: 100%;
  background-color: #14345c;

  .background-container {
    background-image: url("../../../../media/home/header-dekstop.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 70vh;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../../../media/home/header-mobile.webp"); /* Replace with your mobile image URL */
      height: 70vh;
    }
  }
  .contact-header {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    border-style: double;
    border-color: white;
    border-width: 10px 0px 10px 0px;
    .judul-contact {
      padding: 10px;
      font-family: "Poppins";
      font-weight: 600;
      color: white;
      font-size: 24px;
    }
    .nomor-contact {
      font-family: "Poppins";
      font-weight: 400;
      color: white;
      font-size: 16px;
    }
    .button-contact {
      padding-top: 15px;
      padding-left: 20px;
      @media (max-width: 768px) {
        padding-left: 30px;
      }
      .contactus {
        margin-top: -8px;
        display: inline-block;
        padding: 15px 25px;
        cursor: pointer;
        border: none;
        border-radius: 36px;
        background-color: #25d366; /* WhatsApp green */
        color: rgb(237, 237, 237);
        font-size: 15px;
        @media (max-width: 768px) {
          padding: 13px 90px;
        }
      }
    }
  }
}
