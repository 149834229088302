.container-about {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .about {
    width: 80%;
    padding: 20px;
    @media (max-width: 768px) {
      width: 90%;
      height: 50px;
    }
    .carousel-launching-banner {
      width: 100%;

      .launch-banner {
        width: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .slick-dots {
          bottom: 15px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
          color: #b2b2b2;
        }

        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
      }
      .button-cta {
        text-align: center;
        margin-top: 10px;
        .about-whatsapp {
          background-color: #25d366;
          color: white;
          padding: 10px 25px;
          border-radius: 8px;
          border-width: 2px;
          border-color: #25d366;
          &:hover {
            border-width: 2px;
            border-color: #25d366;
            color: #25d366;
            background-color: white;
            transition: 0.3s;
            cursor: pointer;
          }
        }
      }
    }
    .content-about {
      text-align: center;
      margin-top: 20px;
      .judul-about {
        color: #14345c;
        font-family: "Poppins", Sans-serif;
        font-weight: 600;
        font-size: 45px;
        @media (max-width: 768px) {
          font-size: 30px;
        }
      }
      .desk-about {
        color: #14345c;
        font-family: "Poppins", Sans-serif;
        font-weight: 400;
        font-size: 16px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}
