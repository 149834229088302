@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

.container-promo {
  background-color: #14345c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  .container-about {
    width: 75%;
    @media (max-width: 768px) {
      width: 90%;
    }
    .judul-about {
      width: 100%;
      text-align: left;
      color: white;
      font-size: 65px;
      font-weight: 700;
      font-family: "Archivo";
      @media (max-width: 768px) {
        font-size: 36px;
      }
    }
    .desk-about {
      margin-top: 32px;
      color: #e1e1e1;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
    }
  }
  .promo {
    margin-top: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Added justify-content */
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: -15px;
    }
    .containergambar {
      order: 1;
      width: 100%;
      text-align: right;
      @media (max-width: 768px) {
        margin-top: 25px;
        order: 1;
        width: 100%;
        text-align: center;
      }

      .penawarangambar {
        width: 600px;
        height: auto;
        align-self: flex-end;
        box-shadow: 0 0 10px 0;
        border-radius: 5px;
        @media (max-width: 768px) {
          width: 90%;
          height: 100%;
        }
      }
    }

    .containercontent {
      order: 2;
      width: 100%;
      margin-left: 100px;
      text-align: left;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-left: 25px;
        width: 90%;
        order: 2;
        margin-top: 30px;
      }
    }
    .judul-promo {
      width: 80%;
      font-weight: 700;
      font-size: 52px;
      line-height: 1em;
      color: white;
      font-family: "Archivo";
      @media (max-width: 768px) {
        width: 100%;
        font-size: 25px;
        margin-bottom: 17px;
      }
    }
    .disclaimer {
      margin-top: 25px;
      font-size: 13px;
    }
    .pointpenawaran {
      margin-top: 10px;
      flex-direction: row;
      @media (max-width: 768px) {
        width: 100%;
      }
      .penawaranpoin {
        margin-top: 0px;
        color: #f8f812;
        font-weight: 700;
        font-size: 30px;
        font-family: "Archivo";
        line-height: 1.5em;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 24px;
        }
      }
    }
    .button-wa-promo {
      padding-top: 15px;
      padding-bottom: 10px;
      .whatsapp {
        background-color: #25d366;
        color: white;
        padding: 10px 25px;
        border-radius: 8px;
        border-width: 2px;
        border-color: #25d366;
        &:hover {
          border-width: 2px;
          border-color: #25d366;
          color: #25d366;
          background-color: white;
          transition: 0.3s;
          cursor: pointer;
        }
      }
    }
  }
}
