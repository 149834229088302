.footer {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
  .footeratas {
    margin-bottom: 35px;
    .footerpasadena {
      margin-top: 40px;
      display: flex;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      align-items: center;
      justify-content: center;
      .content {
        width: 20%;
        margin-right: 70px;
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-right: 0px;
          margin-bottom: 20px;
        }
        .title {
          font-family: "Poppins", Sans-serif;
          font-size: 65px;
          font-weight: 600;
          text-transform: capitalize;
          font-style: normal;
          text-decoration: none;
          line-height: 1.2em;
          letter-spacing: 0px;
          color: #1c244b;
          margin-bottom: 20px;
          @media (max-width: 768px) {
            text-align: center;
            font-size: 40px;
          }
        }
        .subtitle {
          font-family: "Poppins", Sans-serif;
          margin-bottom: 20px;
          font-size: 22px;
          font-weight: 300;
          text-transform: none;
          font-style: normal;
          text-decoration: none;
          line-height: 1.5em;
          letter-spacing: 0px;
          color: #324a6d;
          @media (max-width: 768px) {
            text-align: center;
            font-size: 22px;
          }
        }
        .divbutton {
          .button {
            padding: 12px 40px;
            font-family: "Poppins", Sans-serif;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            text-transform: capitalize;
            font-style: normal;
            text-decoration: none;
            line-height: 1em;
            letter-spacing: 0px;
            color: #ffffff;
            background-color: #467ff7;
            border-color: #467ff7;
            border-width: 1px;
            border-radius: 50px;
            transition: background-color 0.3s ease, color 0.3s ease;
            &:hover {
              background-color: #fff; // Background color on hover
              color: #467ff7; // Text color on hover
            }
          }
        }
      }
      .pasadenafootercarousel {
        width: 40%;
        @media (max-width: 768px) {
          width: 90%;
        }
        .pasadenaimage {
          width: 100%;
        }
      }
    }
  }

  .footerbawah {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .footerpertama {
      margin-top: 25px;
      margin-bottom: 25px;
      width: 70%;
      display: flex;
      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
      }
      .footerkiri {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        @media (max-width: 768px) {
          width: 100%;
        }
        .logo {
          width: 50%;
        }
        .logotitle {
          color: #1c244b;
          font-family: "Poppins", Sans-serif;
          font-size: 24px;
          font-weight: 600;
        }
      }
      .footertengah {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 768px) {
          margin-top: 25px;
          justify-content: center;
          align-items: center;
          margin-bottom: 25px;
          align-self: center;
          width: 50%;
        }
        .linktitle {
          color: #1c244b;
          font-family: "Poppins", Sans-serif;
          font-size: 22px;
          font-weight: 600;
          text-transform: capitalize;
          font-style: normal;
          text-decoration: none;
          line-height: 1.2em;
          letter-spacing: 0px;
        }
        a {
          text-decoration: none;
          align-self: self-start;
          color: black;
          padding: 8px;
          @media (max-width: 768px) {
            align-self: center;
          }
        }
      }
      .footerkanan {
        width: 40%;
        margin-left: 20px;
        @media (max-width: 768px) {
          width: 100%;
          margin-left: 0px;
        }
        .img {
          @media (max-width: 768px) {
            width: 100%;
            margin-left: 0px;
          }
        }
      }
    }
    .footerkedua {
      display: flex;
      justify-content: center;
      align-items: center;

      color: #c8d5dc;
      font-family: "Poppins", Sans-serif;
      font-size: 16px;
      font-weight: 300;
      text-transform: none;
      font-style: normal;
      text-decoration: none;
      line-height: 1.5em;
      letter-spacing: 0px;

      background-color: #1c244b;
      width: 100%;
      height: 35px;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
