@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.container-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 0 10px 2px;
  position: relative;
  .navbar {
    display: flex;
    width: 1322px;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 15px;
    }
    .logo-navbar {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 40px;
      @media (max-width: 768px) {
        justify-content: center;
        padding: 0px;
        align-items: center;
      }
      .img-logo {
        width: 50%;
        @media (max-width: 768px) {
          width: 70%;
          padding: 5px;
        }
      }
    }
    .menu-navbar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      text-align: center;
      width: 100%;

      a {
        text-decoration: none;
        color: #7a7a7a;
        font-family: "Poppins";
        font-weight: 500;
        text-align: center;
        &:hover {
          color: #14345b;
          border-color: #14345b;
          border-style: solid;
          border-width: 0px 0px 3px 0px;
          padding-bottom: 10px;
        }
      }
      ul {
        @media (max-width: 768px) {
          padding-inline-start: 0px;
        }
      }
      ul li {
        display: inline-block;
        list-style-type: none;
        padding: 20px;
        text-align: center;
      }
    }
  }
}
