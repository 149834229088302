.judul-pasadena {
  margin-top: 20px;
  text-align: center;
  color: #14345c;
  font-size: 52px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.devider {
  background-color: #14345c;
  height: 200px;
  width: 100%;
  transform: scale(1, 1);
  fill: #ffffff;
  @media (max-width: 768px) {
    height: 40px;
  }
}
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .card-carousel {
    box-shadow: 0 0 10px 0;
    padding-bottom: 30px;
    width: 24%;
    border-radius: 10px 10px 10px 10px;
    @media (max-width: 768px) {
      width: 90%;
    }
    .slick-dots {
      li {
        margin: -3px;
      }
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }

    .img {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
    }
  }
  .card1 {
    display: flex;
    flex-direction: column;
    .card {
      width: 380px;
      margin-left: 15px;
      margin-top: 10px;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 0 10px 0;
      @media (max-width: 768px) {
        width: 350px;
        margin-left: 0px;
      }
      .cluster-card-title {
        font-family: "Roboto", Sans-serif;
        display: flex;
        width: 100%;
        flex-direction: row;
        padding: 35px 0 5px 0;
        .cluster-name {
          text-align: center;
          justify-content: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;

          .cluster-name-sub {
            font-size: 25px;
            font-weight: 600;
            color: #14345c;
            font-family: "Poppins", sans-serif;
          }
          //.cluster-name-sub2 {
          //}
        }
        .cluster-price {
          padding-bottom: 10px;
          justify-content: center;
          flex-direction: column;
          display: flex;
          align-items: center;
          background-color: #14345c;
          border-radius: 10px 0px 0px 10px;
          text-align: center;
          color: #ffffff;
          width: 100%;
          .cluster-price-sub {
            font-size: 50px;
            font-weight: 600;
          }
          //.cluster-price-sub2 {
          //}
        }
      }
      .card-separator {
        height: 0.1px;
        margin-top: 15px;
      }
      .gridspek {
        background-color: #131c5a1f;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: #292929;
        @media (max-width: 768px) {
          padding: 15px 0px;
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 5px;
          padding-right: 10px;
          @media (max-width: 768px) {
            padding-right: 20px;
            font-size: 14px;
          }
        }
      }
      .cluster-button {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 10px;

        .cluster-whatsapp {
          display: inline-block;
          padding: 10px 100px;
          cursor: pointer;
          border: none;
          border-radius: 5px;
          background-color: #14345c; /* WhatsApp green */
          color: white;
          font-size: 16px;
          border: solid;
          border-color: #14345c;
          &:hover {
            background-color: white;
            color: black;
            transition: 0.3s;
          }
        }
      }
    }
  }
}
