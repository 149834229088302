.cardhome-containerz {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.judul-zuma {
  margin-top: 20px;
  text-align: center;
  color: #14345c;
  font-size: 52px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.devider {
  background-color: #14345c;
  height: 200px;
  width: 100%;
  transform: scale(1, 1);
  fill: #ffffff;
  @media (max-width: 768px) {
    height: 40px;
  }
}
