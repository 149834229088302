@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.container-promo1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
  }
  .judul-promo {
    font-family: "Poppins";
    width: 75%;
    font-weight: 600;
    font-size: 24px;
    color: #dada06;
    @media (max-width: 768px) {
      width: 95%;
      //text-align: center;
      font-size: 15px;
    }
  }
}

.container-listpromo {
  margin-top: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end; /* Added justify-content */
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: -15px;
  }
  .containergambar {
    order: 1;
    width: 50%;
    text-align: right;
    @media (max-width: 768px) {
      margin-top: 25px;
      order: 1;
      width: 100%;
      text-align: center;
    }

    .penawarangambar {
      width: 500px;
      height: 650px;
      align-self: flex-end;
      box-shadow: 0 0 10px 0;
      border-radius: 7px;
      @media (max-width: 768px) {
        width: 90%;
        height: 100%;
      }
    }
  }

  .containercontent {
    order: 2;
    width: 50%;
    margin-left: 100px;
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      margin-left: 25px;
      width: 90%;
      order: 2;
      margin-top: 30px;
    }
  }
  .disclaimer {
    margin-top: 25px;
    font-size: 13px;
  }
  .pointpenawaran {
    flex-direction: row;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 25px;
      width: 100%;
    }
    .penawaranpoin {
      margin-top: 0px;
      color: #dada06;
      font-family: "Poppins";
      font-size: 25px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .button-wa-promo {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .whatsapp {
      display: inline-block;
      padding: 15px 30px;
      box-shadow: 1px 1px 5px 1px rgb(93, 93, 93);
      cursor: pointer;
      border: none;
      border-radius: 5px;
      background-color: #25d366; /* WhatsApp green */
      color: rgb(237, 237, 237);
      font-size: 15px;
      &:hover {
        background-color: white;
        color: #25d366;
      }
    }
  }
}
