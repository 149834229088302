.card {
  background-color: #14345c;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  width: 350px;
  margin-bottom: 25px;
  height: auto;
  @media (max-width: 768px) {
    width: 350px;
    margin-bottom: 25px;
    margin-right: 0;
  }
  .imgcarousel {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0px 0px;
  }
  .container-title-card {
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: center;

    .titlecard {
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .judul-rumah1 {
        padding-left: 10px;
        color: white;
        font-family: "Poppins";
        font-size: 30px;
        font-weight: 600;
        align-self: flex-start;
      }
      .sub-judul-rumah {
        color: white;
        font-size: 16px;
        font-weight: 400;
        margin-left: 10px;
        margin-bottom: 5px;
        align-self: flex-start;
      }
    }
    .gridspek {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      @media (max-width: 768px) {
        padding: 15px 0px;
      }
      span {
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
        padding-right: 20px;
        @media (max-width: 768px) {
          padding-right: 20px;
          font-size: 14px;
        }
      }
    }
    .cicilan {
      padding: 10px;
      width: 100%;
      background-color: white;
      //padding: 10px;
      border-radius: 0 0px 10px 10px;
      color: #14345c;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .start {
        padding-left: 10px;
        width: 100%;
        flex-direction: row;
        justify-content: left;
        font-family: "Roboto";
        font-weight: 600;
        font-size: 24px;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      .det {
        flex-direction: column;
        width: 100%;
        .angka {
          font-family: "Roboto";
          font-weight: 600;
          font-size: 53px;
          text-align: center;
        }
        .ket {
          font-family: "Roboto";
          font-weight: 600;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    .containerwhatsapp {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      .whatsapp {
        //  display: inline-block;
        padding: 15px 35px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: #61ce70; /* WhatsApp green */
        color: white;
        font-size: 14px;
      }
    }
    .containerpages {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 10px;
      .page {
        padding: 15px 35px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: white; /* WhatsApp green */
        color: #14345c;
        font-size: 14px;
      }
    }
  }
}
