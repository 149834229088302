.produk {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  .ket-cardrumah {
    width: 80%;
    .judul {
      color: #14345c;
      font-family: "Poppins";
      font-size: 52px;
      font-weight: 600;
      text-align: center;
    }
    .subjudul {
      margin-top: 20px;
      color: #14345c;
      font-family: "Poppins";
      font-size: 45px;
      font-weight: 600;
    }
  }
  .section-newlaunch {
    width: 100%;
    margin-top: 20px;
    background-color: #14345c;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: inset 0 0 10px 0;
  }
  .cardhome1-container {
    margin-top: 25px;
    width: 80%;
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0px;
    }
  }
}
