.promohome {
  width: 100%;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 550px;
  }
  .container-promocard {
    width: 100%;
  }
  .container-promo {
    width: 100%;
    .judul-promo {
      width: 75%;
      h2 {
        font-family: "Montserrat";
        font-weight: 400;
        color: #dada06;
        margin-bottom: -20px;
        margin-top: 50px;
        font-size: 22px;
        line-height: 20px;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      h1 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 65px;
        line-height: 1em;
        color: #ffffff;
        margin-bottom: -10px;
        @media (max-width: 768px) {
          font-size: 48px;
        }
      }
    }
  }
}
